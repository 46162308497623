import useShopStore from '@/stores/shop';
import {
  advancedSettingUI as defaultAdvancedSettingUI,
  postPurchaseAdvancedSettingUI,
  advancedSetting as defaultAdvancedSetting,
  postPurchaseSetting,
} from '../../common/utils/const';
import { useSaleFunnel } from '../../sale-funnels/hooks/useSaleFunnel';
import { advancedSettingUIV2, advancedSettingV2 } from '../utils/advancedSettingV2';

export const useGetAdvancedSetting = () => {
  const { isPostPurchasePage } = useSaleFunnel();

  const shopStore = useShopStore();
  const currentVersion = computed(() => shopStore.elementSettingVersion || 'v1');

  const advancedSettingUI = computed(() => {
    if (isPostPurchasePage.value) {
      return postPurchaseAdvancedSettingUI;
    }
    return currentVersion.value === 'v1' ? defaultAdvancedSettingUI : advancedSettingUIV2;
  });

  const advancedSetting = computed(() => {
    if (isPostPurchasePage.value) {
      return postPurchaseSetting;
    }
    return currentVersion.value === 'v1' ? defaultAdvancedSetting : advancedSettingV2;
  });

  return { advancedSettingUI, advancedSetting };
};
